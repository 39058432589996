import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Image } from 'primereact/image';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomDataTable, tableOptions } from '../components/CustomDatatable';
import FeedbackService from '../service/FeedbackService';
import NotifyController from '../utilities/Toast';
import CreateTemplateModal from './CreateTemplate';
import UpdateTemplateModal from './UpdateTemplate';
import moment from 'moment';

const Template = () => {
    const navigate = useNavigate();
    const { page } = useParams();
    const [refresh, setRefresh] = useState(false);
    const [isShowCreationModal, setIsShowCreationModal] = useState(false);
    const [editModalData, setEditModalData] = useState({
        show: false,
        id: null
    });

    const onOptionChange = async (option: tableOptions) => {
        const feedbacks = await FeedbackService.getInstance()
            .getTemplate(option.page, option.rowPerPage, option.orderType, option.order, option.filter, option.selectedCol ?? "")
            .catch((error) => {
                NotifyController.error(error?.message);
                console.log(error);
            });
        return { total: feedbacks.data.totalRecord, data: feedbacks.data.items };
    };

    const bodyAssetUrl = (rowData: any) => {
        return (
            <div className="p-3">
                <Image src={rowData.avatar_url} alt={rowData.avatar_url} width="120" preview />
            </div>
        );
    };

    const bodyAsset2Url = (rowData: any) => {
        return (
            <div className="p-3">
                <Image src={rowData.image_url} alt={rowData.image_url} width="120" preview />
            </div>
        );
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        if (!timestamp) return <span></span>;

        // Convert the timestamp using moment and adjust for GMT+7 timezone
        const formattedDateTime = moment(timestamp)
            .utcOffset(7) // Set offset for GMT+7
            .format('YYYY-MM-DD HH:mm:ss'); // Format the date as 'yyyy-MM-dd HH:mm:ss'

        return <span>{formattedDateTime}</span>;
    };


    const handleDeleteButtonClick = async (rowData: any) => {
        const confirmed = window.confirm('Are you sure you want to delete?');
        if (confirmed) {
            await FeedbackService.getInstance()
                .deleteTemplate(rowData._id)
                .then(() => {
                    NotifyController.success('Delete success');
                    setRefresh(!refresh);
                })
                .catch((error) => {
                    NotifyController.error(error?.message);
                    console.log(error);
                });
        }
    };

    const handlePageChange = (page: any) => {
        navigate(`/template/${page}`);
    };

    const handleCreateButtonClick = () => {
        setIsShowCreationModal(true);
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Generated Image</h5>
                    <div className="p-mb-3 mb-3">
                        {/* Create button */}
                        <Button label="Create" className="p-button-success" onClick={handleCreateButtonClick} />
                    </div>
                    <CustomDataTable onOptionChange={onOptionChange} page={parseInt(page ?? '1')} handlePageChange={handlePageChange} refresh={refresh}>
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Created" headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="group" header="Group" sortable filter headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="type" header="Type" sortable filter headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="image_count" sortable header="Total Generation" headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="url_v2" body={bodyAssetUrl} header="Avatar" headerStyle={{ width: 120 }}></Column>
                        <Column field="url_v2" body={bodyAsset2Url} header="Image" headerStyle={{ width: 120 }}></Column>
                        <Column field="prompt" header="Prompt" filter headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column
                            body={(rowData: any) => (
                                <div className="flex">
                                    <Button
                                        id="button"
                                        className="p-button-primary mx-2"
                                        onClick={() =>
                                            setEditModalData({
                                                show: true,
                                                id: rowData._id
                                            })
                                        }
                                    >
                                        Edit
                                    </Button>
                                    <Button id="button" className="p-button-danger" onClick={() => handleDeleteButtonClick(rowData)}>
                                        Delete
                                    </Button>
                                </div>
                            )}
                            header="Action"
                            headerStyle={{ width: '6rem', textAlign: 'center' }}
                            style={{ textAlign: 'center' }}
                        />
                    </CustomDataTable>
                    {/* Modal */}
                    {isShowCreationModal && (
                        <CreateTemplateModal
                            onClose={() => {
                                setRefresh((preValue) => !preValue);
                                setIsShowCreationModal(false);
                            }}
                        />
                    )}
                    {editModalData.show && (
                        <UpdateTemplateModal
                            id={editModalData.id}
                            onClose={() => {
                                setRefresh((preValue) => !preValue);
                                setEditModalData({
                                    show: false,
                                    id: null
                                });
                            }}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Template;
