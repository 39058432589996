import { useState } from 'react';
import { Column } from 'primereact/column';
import { CustomDataTable, tableOptions } from '../components/CustomDatatable';
import NotifyController from '../utilities/Toast';
import FeedbackService from '../service/FeedbackService';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';


const GeneratedImage = () => {
    const navigate = useNavigate();
    const { page } = useParams();

    const [refresh, setRefresh] = useState<boolean>(false)

    const onOptionChange = async (option: tableOptions) => {
        const feedbacks = await FeedbackService.getInstance().getListGeneratedImage(
            option.page,
            option.rowPerPage,
            option.orderType,
            option.order,
            option.filter
        ).catch((error) => {
            NotifyController.error(error?.message)
            console.log(error);
        })
        return { total: feedbacks.data.totalRecord, data: feedbacks.data.items }
    }

    const bodyAssetUrl = (rowData: any) => {
        return rowData.url_v2.map((item: any) => {
            return (
                <div className="p-3">
                    <Image src={item} alt={item} width="120" preview />
                </div>
            )
        })
    };

    const bodyFormattedDateTime = (rowData: any) => {
        const timestamp = rowData.created_ts;
        if (!timestamp) return <span></span>;

        // Convert the timestamp using moment and adjust for GMT+7 timezone
        const formattedDateTime = moment(timestamp)
            .utcOffset(7) // Set offset for GMT+7
            .format('YYYY-MM-DD HH:mm:ss'); // Format the date as 'yyyy-MM-dd HH:mm:ss'

        return <span>{formattedDateTime}</span>;
    };

    const handleDetailButtonClick = (rowData: any) => {
        navigate(`/generated-image/detail/${rowData._id}`)
    };

    const handlePageChange = (page: any) => {
        navigate(`/generated-image/${page}`)
    };

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <h5>Generated Image</h5>
                    <CustomDataTable
                        onOptionChange={onOptionChange}
                        refresh={refresh}
                        page={parseInt(page ?? '1')}
                        handlePageChange={handlePageChange}
                    >
                        <Column
                            body={(rowData: any) => (
                                <Button onClick={() => handleDetailButtonClick(rowData)}>Detail</Button>
                            )}
                            header="Action"
                            headerStyle={{ width: '6rem', textAlign: 'center' }}
                            style={{ textAlign: 'center' }}
                        />
                        <Column field="created_ts" sortable body={bodyFormattedDateTime} header="Created" headerStyle={{ minWidth: '10rem' }}></Column>
                        <Column field="is_sub" header="Is Sub" sortable filter headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="api_type" header="Type" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="url_v2" body={bodyAssetUrl} header="Asset" headerStyle={{ width: 120 }}></Column>
                        <Column field="status" header="Status" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="device_token" header="Device ID" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                        <Column field="reason" header="Reason" sortable headerStyle={{ minWidth: '10rem' }} ></Column>
                    </CustomDataTable>

                </div>
            </div>
        </div>
    );
};

export default GeneratedImage;
